"use client";

// External Imports
import React, { useState, useEffect } from "react";
import clsx from "clsx";

// Internal Imports
import { getDefaultACFSettings } from "@/helpers/getDefaultACFSettings";
import styles from "@/styles/ProductSpecTable.module.css";
import CompareButton from "@/components/filterButtons/compareButton";

// Type Imports
import { IPage } from "@/types/wordpress-types";
import { IProductComparison } from "@/types/ACFLayouts/ProductComparison";

type Props = {
  data: IProductComparison;
  parentWidth?: number;
  nested?: boolean;
};

export const ProductComparison = ({ data, nested = false }: Props) => {
  const reqUrl =
    `${process.env.NEXT_PUBLIC_API_URL}/wp-json/wp/v2/pn-products` +
    `?pn-products-category=${data?.comparison_term}&exclude=${data.product.ID}`;
  const { specification }: { specification: { [key: string]: string } } =
    data?.product?.acf;

  // State
  const [openMenu, setOpenMenu] = useState<string | null>(null);
  const [comparisonProducts, setComparisonProducts] = useState<IPage[] | null>(
    null,
  );
  const [comparisonProduct, setComparisonProduct] = useState<IPage | null>(
    null,
  );

  // Refs
  const compareMenuRef = React.useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    async function fetchPosts() {
      let res = await fetch(reqUrl);
      let data = await res.json();
      setComparisonProducts(data);
    }

    fetchPosts();
  }, [reqUrl]);

  // Handle Function for filter menu
  const handleToggle = (menuId: string) => {
    setOpenMenu((prevMenu) => (prevMenu === menuId ? null : menuId));
  };

  function handleProductClick(product: IPage | null) {
    setComparisonProduct(product);
  }

  // Default ACF Settings
  const { hide, paddings, margins, classes, id, backgroundColor, bgImgStyles } =
    getDefaultACFSettings(data);

  // Group all main layout classes here
  const combinedClasses = clsx(
    "relative",
    "acf-layout",
    "product-comparison-block",
    paddings,
    margins,
    classes,
  );

  if (hide || !specification) {
    return <></>;
  }

  return (
    <section
      id={id}
      className={`${combinedClasses}`}
      style={{ ...bgImgStyles, backgroundColor }}
    >
      <div
        className={`content content-flow ${!nested ? data.wrapper_width : ""}`}
      >
        <div className="flex items-start justify-end">
          <CompareButton
            ref={compareMenuRef}
            comparisonProducts={comparisonProducts}
            value={comparisonProduct}
            handleClick={handleProductClick}
            isOpen={openMenu === "compare-product"}
            handleToggle={handleToggle}
          />
        </div>

        <div className={styles.specTable}>
          <table className={styles.desktop}>
            <thead>
              <tr>
                <th></th>
                <th>{specification.name}</th>

                {comparisonProduct?.title?.rendered && (
                  <th>{comparisonProduct?.title?.rendered}</th>
                )}
              </tr>
            </thead>

            <tbody>
              {Object.entries(specification).map(([key, value], index) => {
                const replaceUnderscores = key.replace(/_/g, " ");
                let formattedKey =
                  replaceUnderscores.trim().charAt(0).toUpperCase() +
                  replaceUnderscores.slice(1).toLowerCase();

                let comparisonKey;
                let comparisonValue;

                if (comparisonProduct) {
                  comparisonKey =
                    Object.entries(comparisonProduct?.acf?.specification)[
                      index
                    ]?.[0] ?? false;
                  comparisonValue =
                    Object.entries(comparisonProduct?.acf?.specification)[
                      index
                    ]?.[1] ?? false;
                }

                if (formattedKey.length === 3) {
                  formattedKey = formattedKey.toUpperCase();
                }

                return (
                  <tr key={key}>
                    <th className={`w-[20%] ${styles.label}`}>
                      {formattedKey}
                    </th>
                    <td className={`w-auto ${styles.value}`}>{value}</td>

                    {comparisonProduct && comparisonValue && (
                      <td
                        key={comparisonKey}
                        className={`w-[40%] ${styles.value}`}
                      >
                        {comparisonValue}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {/* Mobile layout */}
          <div className={styles.mobile}>
            {Object.entries(specification).map(([key, value], index) => {
              const replaceUnderscores = key.replace(/_/g, " ");
              let formattedKey =
                replaceUnderscores.trim().charAt(0).toUpperCase() +
                replaceUnderscores.slice(1).toLowerCase();

              if (formattedKey.length === 3) {
                formattedKey = formattedKey.toUpperCase();
              }

              return (
                <div key={key} className={styles.specItem}>
                  <strong className={styles.label}>{formattedKey}</strong>
                  <p className={styles.value}>{value}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};
