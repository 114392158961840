// External imports
import React from "react";
import Image from "next/image";
import parse from "html-react-parser";
import Link from "next/link";

// Internal imports
import { ButtonGroup } from "@/components/buttons/ButtonGroup";
import { calculateImageSize } from "@/helpers/calculateImageSize";
import cardStyles from "@/styles/Cards.module.scss";

// Type Imports
import type { ICard } from "@/types/ACFLayouts/StaticCards";
import { ICounterCard } from "@/types/ACFLayouts/CounterCards";

type RenderProps = {
  card: ICard;
  cardClasses: string;
  contentWidth?: string;
  columns: number;
};

type StaticCardProps = {
  card: ICard | ICounterCard;
  contentWidth?: string;
  columns: number;
};

export function RenderStaticCards({
  card,
  cardClasses,
  contentWidth,
  columns,
}: RenderProps) {
  let rel: string = "";

  if (typeof card.link_card === "object") {
    rel = card?.link_card.target === "_blank" ? "noopener noreferrer" : "";
  }

  return (
    <>
      <article className={`${cardClasses}`}>
        {typeof card.link_card === "object" ? (
          <Link
            href={card?.link_card.url}
            target={card?.link_card.target}
            rel={rel}
          >
            <StaticCard
              card={card}
              columns={columns}
              contentWidth={contentWidth}
            />
          </Link>
        ) : (
          <StaticCard
            card={card}
            columns={columns}
            contentWidth={contentWidth}
          />
        )}
      </article>
    </>
  );
}

export function StaticCard({ card, contentWidth, columns }: StaticCardProps) {
  const sizes = calculateImageSize(columns);

  return (
    <>
      <div className={`${cardStyles.media}`}>
        <Image
          src={`${process.env.NEXT_PUBLIC_API_URL}${card?.image.url}`}
          alt={card?.image.alt}
          width={card?.image.width}
          height={card?.image.height}
          loading={"lazy"}
          sizes={sizes}
        />
      </div>

      <div
        className={`${cardStyles["card-body"]} ${contentWidth} pt-5 content content-flow`}
      >
        <div className="content content-flow">
          {parse(card?.card_text)}

          {card?.links && <ButtonGroup links={card.links} />}
        </div>
      </div>
    </>
  );
}
