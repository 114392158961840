"use client";
import type { PreloadAs, PreloadOptions } from "react-dom";
import { preload } from "react-dom";

interface ResourceToPreload {
  href: string;
  /**
   * The resource type to preload.
   * @example
   * "script"
   * <link rel="preload" as="script" href="https://example.com/script.js">
   */
  as: PreloadAs;
  fetchPriority?: PreloadOptions["fetchPriority"];
}

/**
 * Preloads resources to improve performance.
 * @param resources - The resources to preload.
 */
export const Preloader = ({
  resources,
}: {
  resources: ResourceToPreload[];
}) => {
  resources.forEach(({ href, as, fetchPriority }) => {
    preload(href, {
      as,
      fetchPriority,
    });
  });

  return null;
};
