import { Column, Grid } from "@/components/layout/Grid";
import React from "react";
import Image from "next/image";

interface LoadingStateProps {
  columns: number;
}

export function IssuesSkeleton({ columns }: LoadingStateProps) {
  return (
    <>
      <Grid
        columns={columns}
        classes="mt-10 min-h-[40vh] relative same-height-all-group"
      >
        {/* Columns */}
        {[...Array(20)].map((post, index) => (
          <Column key={`skeleton-${index}`} classes={"relative"}>
            <div className="relative animate-pulse">
              <Image
                src={"/issues-skeleton.png"}
                alt={""}
                height={291}
                width={208}
              />
            </div>
          </Column>
        ))}
      </Grid>
    </>
  );
}
