// External Imports
import parse from "html-react-parser";
import React from "react";
import dynamic from "next/dynamic";

// Internal Imports
import styles from "@/components/acfFlexibleContentLayouts/LocateStockists/accordions/accordions.module.scss";

// Type Imports
import { PanelContentType } from "@/types/ACFLayouts/Accordion";

// Lazy Load Imports
const ACFAccordionBuilder = dynamic(() =>
  import("@/components/acfFlexibleContent/ACFAccordionBuilder").then(
    (mod) => mod.ACFAccordionBuilder,
  ),
);

interface AccordionProps {
  accordionTitle: string;
  panelContent: PanelContentType;
}

export function AccordionUI({
  accordionTitle,
  panelContent,
}: AccordionProps) {
  const [openAccordion, setOpenAccordion] = React.useState<string | boolean>(false);

  const handleAccordionToggle = () => {
    setOpenAccordion((prev) => !prev);

    // Dispatch a custom event when the accordion is opened
    const event = new CustomEvent("accordionOpened");
    window.dispatchEvent(event);
  };

  const accordionTitleSlug = accordionTitle
    .toLowerCase()
    .replace(": ", "-")
    .replace(" ", "-")
    .replace(" ", "-")
    .replace("&amp; ", "");

  return (
    <>
      {/*  Accordion - Regions  */}
      <button
        id={`accordion-button-${accordionTitleSlug}`}
        className={`${styles.accordion} ${styles["accordion-ui"]} accordion accordion-${accordionTitleSlug} ${accordionTitleSlug} ${openAccordion ? "active " + styles.active : "inactive " + styles.inactive}`}
        aria-expanded={openAccordion ? "true" : "false"}
        onClick={handleAccordionToggle}
      >
        <span className={`accordion-title ${styles.accordionTitle}`}>{parse(accordionTitle)}</span>
      </button>

      {/*  Panel - lifted state controlled by parent.
       Only one panel open at a time. Move state down to change this.
       */}
      <div
        id={`accordion-panel-${accordionTitleSlug}`}
        className={`panel ${styles.panel} panel-${accordionTitleSlug} content content-flow ${openAccordion ? styles.active : styles.inactive}`}
        aria-labelledby={`accordion-button-${accordionTitleSlug}`}
      >
        <ACFAccordionBuilder data={panelContent} />
      </div>
    </>
  );
}
