"use client";

import React, { useContext } from "react";
import parse from "html-react-parser";
import clsx from "clsx";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Image from "next/image";
import Link from "next/link";

import { getDefaultACFSettings } from "@/helpers/getDefaultACFSettings";
import { INewsletterSignup } from "@/types/ACFLayouts/NewsletterSignUp";
import { useCompanyData } from "@/hooks/useCompanyData";

type Props = {
  data: INewsletterSignup;
  parentWidth?: number;
  nested?: boolean;
};

const SignUpFormSchema = yup
  .object({
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Please fill in your email"),
    terms: yup
      .bool() // use bool instead of boolean
      .oneOf([true], "You must accept the terms and conditions"),
  })
  .required();

type FormType = yup.InferType<typeof SignUpFormSchema>;

export const NewsletterSignUp = ({ data, nested = false }: Props) => {
  const [showSuccessPage, setShowSuccessPage] = React.useState(false);
  const [error, setError] = React.useState("");

  const companyData = useCompanyData();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignUpFormSchema),
  });

  // Default ACF Settings
  const { hide, paddings, margins, classes, id, backgroundColor } =
    getDefaultACFSettings(data);

  // Group all main layout classes here
  const combinedClasses = clsx(
    "relative",
    "acf-layout",
    "newsletter-sign-up-block",
    paddings,
    margins,
    classes
  );

  // Submit Handler...
  const onSubmit = async (submissionData: FormType) => {
    setError(""); // Reset Error

    const dataFields = [
      {
        key: "PN",
        value: "YES",
      },
      {
        key: "PN_SOURCE",
        value: "PN_WEB",
      },
    ];

    const formData = new FormData();

    formData.append("email", submissionData.email);
    formData.append("optInType", "single");
    formData.append("emailType", "Html");

    // Append the dataFields array as a JSON string
    formData.append("dataFields", JSON.stringify(dataFields));

    const reqUrl = `/api/v1/dot-digital/newsletter-subscribe/?id=${data?.dot_digital_newsletter_id}`;

    try {
      const response = await fetch(reqUrl, {
        method: "POST",
        body: formData,
      }).then((res) => res.json());

      if (!response.error) {
        setShowSuccessPage(true);
      } else {
        setError(response.error || "An error occurred while subscribing.");
      }
    } catch (error) {
      console.error("Failed to fetch cart:", error);
      setError("An unexpected error occurred.");
    }
  };

  if (hide) {
    return <></>;
  }

  return (
    <section id={id} className={`${combinedClasses}`}>
      <div
        className={`content relative p-5 text-white ${
          !nested ? data.wrapper_width : ""
        }`}
        style={{ backgroundColor }}
      >
        {/*  Background Image  */}
        {data?.block_background_image && (
          <Image
            src={`${process.env.NEXT_PUBLIC_API_URL}${data.block_background_image}`}
            alt={""}
            fill
            className={
              "max-lg:hidden object-cover object-center xl:object-[80%_center] z-0"
            }
          />
        )}

        <div className="content flex flex-col z-5 relative gap-1.5 lg:max-w-[65%] xl:max-w-[55%]">
          <h2 className="h1 leading-tight text-white">
            {data?.form_title
              ? parse(data.form_title)
              : "Subscribe to Photography News Weekly"}
          </h2>
          <p className="font-semibold">
            {data?.form_subtitle
              ? parse(data.form_subtitle)
              : "Get a weekly dose of the latest news, reviews and inspiration direct to your email inbox"}
          </p>
        </div>

        {showSuccessPage ? (
          <>
            <div className="flex flex-wrap z-5 relative items-center gap-2.5 mt-3">
              <div className="icon">
                <Image
                  src={"/icons/email-success.svg"}
                  alt={""}
                  width={48}
                  height={48}
                />
              </div>

              <div className="confirmation success content">
                <h2>Subscription confirmed</h2>
                <p className="mt-0">
                  We’ll send you the the next instalment in this series every
                  week
                </p>
              </div>
            </div>

            <p className="mt-3 z-5 relative">
              You may opt-out at any time.{" "}
              <a
                className="font-semibold underline"
                href={
                  companyData?.company_data.terms_conditions_page?.url ||
                  "/terms-and-conditions/"
                }
                target={
                  companyData?.company_data.terms_conditions_page?.target ||
                  "_blank"
                }
              >
                Terms and conditions
              </a>{" "}
              and{" "}
              <a
                className="font-semibold underline"
                href={
                  companyData?.company_data.privacy_policy_page?.url ||
                  "/privacy-policy/"
                }
                target={
                  companyData?.company_data.privacy_policy_page?.target ||
                  "_blank"
                }
              >
                Privacy Policy
              </a>
              .
            </p>
          </>
        ) : (
          <form
            className={`relative mt-3 flex items-center flex-wrap gap-5 lg:max-w-[50%]`}
            onSubmit={handleSubmit(onSubmit)}
            id={"acf-newsletter-signup-form"}
          >
            {/* Email  */}
            <div className="email flex flex-col order-1 flex-1 grow-[2]">
              <label hidden htmlFor="email" className={clsx("text-sm")}>
                Email*
              </label>
              <input
                {...register("email")}
                placeholder={`johnsmith@example.com`}
                className={clsx(
                  "border-box text-pn-black-base h-12 flex items-center px-5 bg-white w-full",
                  "placeholder:text-pn-placeholder-base border border-solid outline outline-2 outline-transparent",
                  "hover:outline-pn-news-base/30 focus:outline-pn-news-base/30 focus:placeholder:text-transparent",
                  errors.email ? "border-pn-red-base" : "border-pn-black-base"
                )}
              />
              {errors.email && (
                <p className="text-sm mt-1 text-white">
                  {errors.email.message}
                </p>
              )}
            </div>

            {/*  Terms & Conditions  */}
            <div className="terms-conditions w-full flex flex-col justify-center order-2 md:order-3">
              <label
                htmlFor="newsletter-terms"
                className={clsx("text-sm block leading-tight select-none")}
              >
                <input
                  {...register("terms")}
                  className="mr-2"
                  type={"checkbox"}
                  name={"terms"}
                  id={"newsletter-terms"}
                />
                I agree to Photography News’{" "}
                <a
                  className="font-semibold underline"
                  href={companyData?.company_data.terms_conditions_page?.url}
                  target={
                    companyData?.company_data.terms_conditions_page?.target
                  }
                >
                  terms and conditions
                </a>
                . For information about how we use your data, see our{" "}
                <a
                  className="font-semibold underline"
                  href={companyData?.company_data.privacy_policy_page?.url}
                  target={companyData?.company_data.privacy_policy_page?.target}
                >
                  privacy policy
                </a>
              </label>
              {errors.terms && (
                <p className="text-sm mt-1 text-white">
                  {errors.terms.message}
                </p>
              )}
            </div>

            {/* Submit Button */}
            <div className="email flex flex-col flex-1 order-3 md:order-2 md:max-w-[170px]">
              <button className="btn btn-tertiary" type="submit">
                Subscribe
              </button>
              {errors.email && (
                <p className="text-sm mt-1 text-white invisible">
                  {errors.email.message}
                </p>
              )}
            </div>

            {/*  Form Error  */}
            <p
              className={`w-full order-4 bg-pn-red-base/90 px-2 form-error error ${
                error ? "flex" : "hidden"
              }`}
            >
              {error}
            </p>
          </form>
        )}
      </div>
    </section>
  );
};
