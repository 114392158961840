"use client"

// External imports
import React from "react";
import clsx from "clsx";
import dynamic from "next/dynamic";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules'

import 'swiper/css/navigation';

// Internal imports
import { getDefaultACFSettings } from "@/helpers/getDefaultACFSettings";
import { LayoutIntro } from "@/components/LayoutIntro";

// Type Imports
import type { IVideos } from "@/types/ACFLayouts/Videos";

// Lazy Load Imports
const Caption = dynamic(() =>
  import("@/components/ui/Caption").then((mod) => mod.Caption),
);
const VideoPlayer = dynamic(
  () =>
    import("@/components/videoPlayers/VideoPlayer").then(
      (mod) => mod.VideoPlayer,
    ),
  { ssr: false },
);
const YouTubePlayer = dynamic(
  () =>
    import("@/components/videoPlayers/YouTubePlayer").then(
      (mod) => mod.YouTubePlayer,
    ),
  { ssr: false },
);
const VimeoPlayer = dynamic(
  () =>
    import("@/components/videoPlayers/VimeoPlayer").then(
      (mod) => mod.VimeoPlayer,
    ),
  { ssr: false },
);

type Props = {
  data: IVideos;
  parentWidth?: number;
  nested?: boolean;
};

export const Videos = ({ data, nested = false }: Props) => {
  const { hide, paddings, margins, classes, id, backgroundColor, bgImgStyles } =
    getDefaultACFSettings(data);

  // Group all main layout classes here
  const combinedClasses = clsx(
    "relative",
    "acf-layout",
    "videos-block",
    paddings,
    margins,
    classes,
  );

  const videos = data.videos;
  const videosExist = typeof videos !== "boolean";

  const gridClasses: { [key: number]: string } = {
    1: `grid-cols-1`,
    2: `grid-cols-1 sm:grid-cols-2`,
    3: `grid-cols-1 sm:grid-cols-2 md:grid-cols-3`,
    4: `grid-cols-1 sm:grid-cols-2 md:grid-cols-4`,
    5: `grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:md:grid-cols-5`,
    6: `grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:md:grid-cols-6`,
  };

  if (hide || !videosExist) {
    return <></>;
  }

  return (
    <>
      <div
        id={id}
        className={`${combinedClasses}`}
        style={{ ...bgImgStyles, backgroundColor }}
      >
        <div className={`${!nested ? data.wrapper_width : ""}`}>
          {/*  Grid Cards - No Slider  */}
          {!data.display_in_a_slider && (
            <>
              {/* Intro */}
              {data.intro && <LayoutIntro intro={data.intro} />}

              {/* Main Section */}
              <section>
                <ul
                  className={`grid gap-[5vw] list-none ml-0 md:gap-[3vw] xl:gap-[2vw] ${gridClasses[+data.number_of_columns]}`}
                >
                  {/* Columns */}
                  {videos.map((video, index) => {
                    const localVideos = [];

                    if (video.type_of_video === "local" && video.mp4_url) {
                      localVideos.push({
                        src: `${process.env.NEXT_PUBLIC_API_URL}${video.mp4_url}`,
                        type: "video/mp4",
                      });
                    }
                    if (video.type_of_video === "local" && video.webm_url) {
                      localVideos.push({
                        src: `${process.env.NEXT_PUBLIC_API_URL}${video.webm_url}`,
                        type: "video/webm",
                      });
                    }

                    return (
                      <li key={`${video.type_of_video}-${index}`}>
                        {video.type_of_video === "external" &&
                          video.external_url.includes("youtube") && (
                            <YouTubePlayer url={video.external_url} />
                          )}

                        {video.type_of_video === "external" &&
                          video.external_url.includes("vimeo") && (
                            <VimeoPlayer url={video.external_url} />
                          )}

                        {video.type_of_video === "local" && (
                          <VideoPlayer urls={localVideos} />
                        )}

                        {video?.video_caption && (
                          <Caption>
                            <p>{video.video_caption}</p>
                          </Caption>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </section>
            </>
          )}

          {/* Slider of Videos */}
          {data.display_in_a_slider && (
            <>
              {/* Intro */}
              {data.intro && <LayoutIntro intro={data.intro} />}

              <Swiper
                slidesPerView={+data.number_of_columns}
                spaceBetween={20}
                modules={[Navigation]}
                navigation={{ nextEl: '.swiper-next', prevEl: '.swiper-prev', enabled: true }}
                onInit={(swiper) => setTimeout(() => swiper.update(), 300)}
              >
                <div className="swiper-arrows flex z-[5] gap-2.5 !mt-5">
                  <div className="swiper-arrow swiper-prev"></div>
                  <div className="swiper-arrow swiper-next"></div>
                </div>
                {/* Main Section */}
                {videos.map((video, index) => {
                  const localVideos = [];

                  if (video.type_of_video === "local" && video.mp4_url) {
                    localVideos.push({
                      src: `${process.env.NEXT_PUBLIC_API_URL}${video.mp4_url}`,
                      type: "video/mp4",
                    });
                  }
                  if (video.type_of_video === "local" && video.webm_url) {
                    localVideos.push({
                      src: `${process.env.NEXT_PUBLIC_API_URL}${video.webm_url}`,
                      type: "video/webm",
                    });
                  }

                  return (
                    <SwiperSlide key={`${video.type_of_video}-${index}`}>
                      {video.type_of_video === "external" &&
                        video.external_url.includes("youtube") && (
                          <YouTubePlayer url={video.external_url}/>
                        )}

                      {video.type_of_video === "external" &&
                        video.external_url.includes("vimeo") && (
                          <VimeoPlayer url={video.external_url}/>
                        )}

                      {video.type_of_video === "local" && (
                        <VideoPlayer urls={localVideos}/>
                      )}

                      {video?.video_caption && (
                        <Caption>
                          <p>{video.video_caption}</p>
                        </Caption>
                      )}
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </>
          )}
        </div>
      </div>
    </>
  );
};
