"use client";

import { IHeaderVideos } from "@/types/ACFLayouts/PageHeaderStatic";
import React, { useEffect } from "react";
import { Preloader } from "../optimization/Preloader";
import Image from "next/image";

type Props = {
  data: IHeaderVideos;
  priority?: boolean;
};

export const VideoWithOptimizedPoster = ({ data, priority }: Props) => {
  const [loaded, setLoaded] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);

  useEffect(() => {
    if (!window) return;

    if (window.innerWidth < 500) {
      setIsMobile(true);
    }
  }, [setIsMobile]);

  const handleLoadedData = () => {
    setLoaded(true);
  };

  return (
    <>
      {!loaded && (
        <Image
          src="/home-unsplash-1600x1068.webp"
          alt="Home"
          fill
          className="w-full h-full object-cover"
          priority={priority}
          sizes="100vw"
        />
      )}
      {!isMobile && <Video data={data} handleLoadedData={handleLoadedData} />}
    </>
  );
};

type VideoProps = {
  data: IHeaderVideos;
  handleLoadedData: () => void;
};

const Video = ({ data, handleLoadedData }: VideoProps) => {
  const videoRef = React.useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (!videoRef.current) return;

    const video = videoRef.current;

    video.load();

    return () => {
      video.pause();
    };
  }, []);

  return (
    <>
      <Preloader
        resources={[
          {
            href: data.mp4_url ? data.mp4_url : data.webm_url,
            as: "video",
          },
        ]}
      />
      <video
        ref={videoRef}
        autoPlay
        loop
        muted
        playsInline
        preload="auto"
        className="w-full h-full object-cover"
        onLoadedData={handleLoadedData}
      >
        <source
          src={`${data.mp4_url ? data.mp4_url : data.webm_url}`}
          type="video/mp4"
        />
      </video>
    </>
  );
};
