"use client";

import CountUp from "react-countup";

interface CountersProps {
  counters:
    | false
    | {
        prefix: string;
        count: number;
        suffix: string;
        label: string;
      }[];
}

export function Counters({ counters }: CountersProps) {
  return (
    <>
      {counters &&
        counters.map((counter, index) => (
          <div
            key={counter.label}
            className="counter flex flex-col p-5 gap-5 bg-white"
          >
            <CountUp
              className="counter-stat font-roboto-serif leading-none font-bold text-[28px]"
              end={counter.count}
              prefix={counter.prefix}
              suffix={counter.suffix}
              separator=","
              enableScrollSpy={true}
              scrollSpyOnce={true}
              duration={0.5}
            />
            <p className="counter-label leading-tight">{counter.label}</p>
          </div>
        ))}
    </>
  );
}
