"use client"

// External imports
import React from "react";
import clsx from "clsx";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css/navigation';

// Internal imports
import { handleCardClasses } from "@/helpers/handleCardClasses";
import { RenderStaticCards } from "@/components/cards/StaticCards";
import { LayoutIntro } from "@/components/LayoutIntro";
import { getDefaultACFSettings } from "@/helpers/getDefaultACFSettings";
import { Grid, Column } from "@/components/layout/Grid";
import CardStyles from "@/styles/Cards.module.scss";

// Type Imports
import type { ICards } from "@/types/ACFLayouts/StaticCards";

type Props = {
  data: ICards;
};

export function StaticCards({ data }: Props) {
  // Default ACF Settings
  const { hide, paddings, margins, classes, id, backgroundColor, bgImgStyles } =
    getDefaultACFSettings(data);

  // Group all main layout classes here
  const combinedClasses = clsx(
    "relative",
    "acf-layout",
    "static-cards-block",
    paddings,
    margins,
    classes,
  );

  // Card Classes
  const { cardClasses, horizontalWrapperClasses } = handleCardClasses(data);

  let perView = +data.number_of_columns

  if (data.orientation === "horizontal") {
    // Override the panelsPerView property
    perView = data.horizontal_columns === "stack" ? 1 : 2;
  }

  if (hide) {
    return <></>;
  }

  return (
    <>
      <div
        id={id}
        className={`${combinedClasses}`}
        style={{ ...bgImgStyles, backgroundColor }}
      >
        <div className={`${data.wrapper_width}`}>
          {/*  Grid Cards - No Slider  */}
          {!data.display_in_a_slider && (
            <>
              {/* Intro */}
              {data.intro && <LayoutIntro intro={data.intro} />}

              {/* Horizontal Cards  */}
              {data.orientation === "horizontal" && (
                <>
                  <section
                    className={`${CardStyles["h-cards"]} ${horizontalWrapperClasses}`}
                  >
                    {data.cards.map((card, index) => (
                      <RenderStaticCards
                        key={card.image.ID}
                        card={card}
                        cardClasses={cardClasses}
                        columns={+data.number_of_columns}
                        contentWidth={data.content_width}
                      />
                    ))}
                  </section>
                </>
              )}

              {/* Main Section */}
              {data.orientation === "vertical" && (
                <section className="flex space-x-4">
                  <Grid
                    columns={+data.number_of_columns}
                    classes="same-height-group"
                  >
                    {/* Columns */}
                    {data.cards.map((card, index) => (
                      <Column key={index}>
                        <RenderStaticCards
                          key={card.image.ID}
                          card={card}
                          cardClasses={cardClasses}
                          columns={+data.number_of_columns}
                          contentWidth={data.content_width}
                        />
                      </Column>
                    ))}
                  </Grid>
                </section>
              )}
            </>
          )}

          {/* Slider of Cards */}
          {data.display_in_a_slider && (
            <>
              <section
                className={`${
                  data.orientation === "horizontal" &&
                  CardStyles["h-cards"] + " " + horizontalWrapperClasses
                }`}
              >
                {/* Intro */}
                {data.intro && <LayoutIntro intro={data.intro} />}

                <Swiper
                  slidesPerView={perView}
                  spaceBetween={20}
                  modules={[Navigation]}
                  navigation={{ nextEl: '.swiper-next', prevEl: '.swiper-prev', enabled: true }}
                  onInit={(swiper) => setTimeout(() => swiper.update(), 300)}
                >
                  <div className="swiper-arrows flex z-[5] gap-2.5 !mt-5">
                    <div className="swiper-arrow swiper-prev"></div>
                    <div className="swiper-arrow swiper-next"></div>
                  </div>
                  {data.cards &&
                    data.cards.map((card) => (
                      <SwiperSlide key={card.image.ID}>
                        <RenderStaticCards
                          key={card.image.ID}
                          card={card}
                          cardClasses={cardClasses}
                          columns={+data.number_of_columns}
                          contentWidth={data.content_width}
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              </section>
            </>
          )}
        </div>
      </div>
    </>
  );
}
