import parse from "html-react-parser";
import Image from "next/image";
import { IPodcastFeedResponse } from "@/types/ACFLayouts/PodcastFeed";

interface FullPodcastDescriptionProps {
  podcast: IPodcastFeedResponse;
  toggleContent: () => void;
  classes?: string;
}

export function FullPodcastDescription({
  podcast,
  toggleContent,
  classes,
}: FullPodcastDescriptionProps) {
  return (
    <div
      className={`full-description overflow-hidden break-words whitespace-normal content content-flow basis-full ${classes}`}
    >
      {parse(podcast.acf.description)}
      <button
        onClick={toggleContent}
        className="font-semibold block show-less underline"
      >
        Show less
      </button>

      <div className="mt-10 podcast-links">
        <p className="font-semibold">Listen on</p>

        <div className="podcast-buttons gap-5 my-5 flex flex-wrap justify-start">
          {podcast.acf.apple && (
            <a
              href={podcast.acf.apple}
              target="_blank"
              rel="noopener noreferrer"
              className="btn podcast-btn bg-white"
            >
              <Image
                src="/icons/apple-podcasts.svg"
                alt="Apple Podcast Logo"
                width={24}
                height={24}
              />
              <p className="inline-flex font-medium font-roboto">
                Apple Podcasts
              </p>
            </a>
          )}

          {podcast.acf.spotify && (
            <a
              href={podcast.acf.spotify}
              target="_blank"
              rel="noopener noreferrer"
              className="btn podcast-btn bg-white"
            >
              <Image
                src="/icons/spotify-podcasts.svg"
                alt="Apple Podcast Logo"
                width={24}
                height={24}
              />
              <p className="inline-flex font-medium font-roboto">Spotify</p>
            </a>
          )}

          {podcast.acf.youtube && (
            <a
              href={podcast.acf.youtube}
              target="_blank"
              rel="noopener noreferrer"
              className="btn podcast-btn bg-white"
            >
              <Image
                src="/icons/youtube-podcasts.svg"
                alt="Apple Podcast Logo"
                width={24}
                height={24}
              />
              <p className="inline-flex font-medium font-roboto">YouTube</p>
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
