import CardStyles from "@/styles/Cards.module.scss";
import { ICards } from "@/types/ACFLayouts/StaticCards";
import { IDynamicPosts } from "@/types/ACFLayouts/DynamicPosts";
import { IQueryPosts } from "@/types/ACFLayouts/QueryPosts";
import { ICounterCards } from "@/types/ACFLayouts/CounterCards";

type Props = ICards | ICounterCards | IDynamicPosts | IQueryPosts;

export function handleCardClasses(data: Props) {
  let horizontalWrapperClasses = "";
  let cardClasses = "";

  if (data.orientation === "horizontal") {
    horizontalWrapperClasses = `${CardStyles[data.horizontal_columns]} ${
      CardStyles[data.zz_direction]
    }`;
    cardClasses += ` ${CardStyles["h-card"]}`;

    if (data.horizontal_columns === "stack") {
      horizontalWrapperClasses += ` ${CardStyles[data.stacking]}`;
    }
  } else {
    cardClasses += ` ${CardStyles["v-card"]}`;
  }

  return {
    cardClasses,
    horizontalWrapperClasses,
  };
}
