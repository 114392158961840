import dynamic from "next/dynamic";
import { ILink } from "@/types/ACFLayouts/TextEditor";

const ButtonLink = dynamic(() =>
  import("@/components/buttons/ButtonLink").then((mod) => mod.ButtonLink),
);

type ButtonGroupProps = {
  links: ILink[];
  classes?: string;
};

export function ButtonGroup({ links, classes }: ButtonGroupProps) {
  return (
    <div className={`btn-group flex gap-5 flex-wrap mt-5 lg:mt-10 ${classes}`}>
      {links?.map((link, index) => {
        if (!link?.link?.url) {
          return <></>;
        }

        return <ButtonLink key={`${link.link.url}-${index}`} link={link} />;
      })}
    </div>
  );
}
