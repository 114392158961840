"use client";

import React from "react";
import clsx from "clsx";
import { IAccordion } from "@/types/ACFLayouts/Accordion";
import { getDefaultACFSettings } from "@/helpers/getDefaultACFSettings";
import { AccordionUI } from "@/components/ui/Accordion";
import { LayoutIntro } from "@/components/LayoutIntro";

interface AccordionProps {
  data: IAccordion;
  nested?: boolean;
}

export const Accordion = ({ data, nested = false }: AccordionProps) => {

  const { hide, paddings, margins, classes, id, backgroundColor, bgImgStyles } =
    getDefaultACFSettings(data);

  // Group all main layout classes here
  const combinedClasses = clsx(
    "relative",
    "acf-layout",
    "accordion-block",
    paddings,
    margins,
    classes,
  );

  if (hide) {
    return <></>;
  }

  return (
    <div
      id={id}
      className={`${combinedClasses}`}
      style={{ ...bgImgStyles, backgroundColor }}
    >
      <div className={`flex flex-col ${!nested ? data.wrapper_width : ""}`}>
        {data.intro && <LayoutIntro intro={data.intro} />}

        {data.accordions.map((accordion, index) => (
          <AccordionUI
            key={accordion.accordion_title}
            accordionTitle={accordion.accordion_title}
            panelContent={accordion.panel_content}
          />
        ))}
      </div>
    </div>
  );
};
