"use client";

import React, { SetStateAction } from "react";
import parse from "html-react-parser";
import Image from "next/image";
import { IPodcastFeedResponse } from "@/types/ACFLayouts/PodcastFeed";
import { FullPodcastDescription } from "@/components/acfFlexibleContentLayouts/PodcastFeed/FullDescription";

interface PodcastDescriptionProps {
  podcast: IPodcastFeedResponse;
  showMore: boolean;
  toggleShowMore: React.Dispatch<SetStateAction<boolean>>;
}

export function PodcastDescription({
  podcast,
  showMore,
  toggleShowMore,
}: PodcastDescriptionProps) {
  function handleToggleContent() {
    toggleShowMore((prev) => !prev);
  }

  return (
    <>
      {!showMore ? (
        <>
          <div className="max-md:hidden mt-5 short-description content-flow content">
            {podcast?.acf?.short_description &&
              parse(podcast.acf?.short_description)}
            <button
              onClick={handleToggleContent}
              className="font-semibold inline-block show-more underline"
            >
              Show more
            </button>
          </div>
          <div className="md:hidden short-description content-flow content">
            <button
              onClick={handleToggleContent}
              className="font-semibold inline-block show-more underline"
            >
              Show more
            </button>
          </div>
        </>
      ) : (
        <FullPodcastDescription
          classes="max-md:hidden"
          podcast={podcast}
          toggleContent={handleToggleContent}
        />
      )}
    </>
  );
}
