"use client";

// External Imports
import clsx from "clsx";
import React, { ChangeEvent } from "react";
import dynamic from "next/dynamic";

// Internal Imports
import { getDefaultACFSettings } from "@/helpers/getDefaultACFSettings";

// Type Imports
import { ILocateStockists } from "@/types/ACFLayouts/LocateStockists";
import { IPost } from "@/types/wordpress-types";
import { Column, Grid } from "@/components/layout/Grid";

// Lazy Load Imports
const SVGMap = dynamic(() =>
  import(
    "@/components/acfFlexibleContentLayouts/LocateStockists/Map/SVGMap"
  ).then((mod) => mod.SVGMap),
);
const LocationAccordions = dynamic(() =>
  import(
    "@/components/acfFlexibleContentLayouts/LocateStockists/accordions/LocationAccordions"
  ).then((mod) => mod.LocationAccordions),
);

interface LocateStockistsProps {
  data: ILocateStockists;
  nested?: boolean;
}

export function LocateStockists({
  data,
  nested = false,
}: LocateStockistsProps) {
  const [postcode, setPostcode] = React.useState("");
  const [openAccordion, setOpenAccordion] = React.useState<string | boolean>(
    false,
  );
  const [filteredStockists, setFilteredStockists] = React.useState(
    data.stockists,
  );
  const [isFocussed, setIsFocussed] = React.useState(false);

  const handleAccordionToggle = (region: string) => {
    setOpenAccordion((prevRegion) => (prevRegion === region ? false : region));
  };

  const distanceNumber =
    typeof data.location_distance === "number" ? data.location_distance : 50;

  // Default ACF Settings
  const { hide, paddings, margins, classes, id, backgroundColor, bgImgStyles } =
    getDefaultACFSettings(data);

  // Group all main layout classes here
  const combinedClasses = clsx(
    "relative",
    "acf-layout",
    "locate-stockists-block",
    "!mt-10",
    paddings,
    margins,
    classes,
  );

  function slugifyRegion(region: string) {
    return region.toLowerCase().replace(" ", "-").replace("&amp; ", "");
  }

  const calculateDistance = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
    unit = "M",
  ) => {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") {
      dist = dist * 1.609344;
    }
    if (unit === "N") {
      dist = dist * 0.8684;
    }
    return Number(dist.toFixed(1));
  };

  interface GroupedStockists {
    [region: string]: {
      [county: string]: IPost[];
    };
  }

  const groupStockistsByRegionAndCounty = (stockists: IPost[]) => {
    const grouped: GroupedStockists = {};

    stockists.forEach((stockist) => {
      const [region, county] = stockist.taxonomies;

      if (!grouped[region]) {
        grouped[region] = {};
      }

      if (!grouped[region][county]) {
        grouped[region][county] = [];
      }

      grouped[region][county].push(stockist);
    });

    // Sort regions and counties alphabetically
    const sortedGrouped: GroupedStockists = {};

    Object.keys(grouped)
      .sort()
      .forEach((region) => {
        sortedGrouped[region] = {};
        Object.keys(grouped[region])
          .sort()
          .forEach((county) => {
            sortedGrouped[region][county] = grouped[region][county];
          });
      });

    return sortedGrouped;
  };

  function handlePostcodeSearch(event: ChangeEvent<HTMLInputElement>) {
    setPostcode(event.target.value);
  }

  React.useEffect(() => {
    let typingTimer: NodeJS.Timeout;

    if (postcode === "") {
      setFilteredStockists(data.stockists);
    } else {
      typingTimer = setTimeout(() => {
        fetchUserLocation(postcode).then((location) => {
          if (location) {
            const filtered = data.stockists
              .filter((stockist) => {
                const distance = calculateDistance(
                  location.lat,
                  location.lng,
                  stockist.acf.latitude,
                  stockist.acf.longitude,
                  "M",
                );
                stockist.distance = distance;
                return distance <= distanceNumber; // Filter stockists within 75 miles - change this as needed.
              })
              .sort((a, b) => a.distance - b.distance);

            setFilteredStockists(filtered);
          }
        });
      }, 400);
    }

    return () => clearTimeout(typingTimer);
  }, [data.stockists, postcode, distanceNumber]);

  /**
   * API Call to Google to get geolocation data for user's postcode..
   * @param postcode
   * */
  const fetchUserLocation = async (postcode: string) => {
    const postcodeString = postcode.toUpperCase();

    const response = await fetch(
      `https://maps.google.com/maps/api/geocode/json?address=${postcodeString}&sensor=false&key=AIzaSyCEwg8XtK4i_Cu6oTqupM7ITMIUad9HgjI`,
    );
    const data = await response.json();

    if (
      data.results[0] &&
      data.results[0].geometry &&
      data.results[0].geometry.location
    ) {
      const { lat, lng } = data.results[0].geometry.location;
      return { lat, lng };
    }
  };

  const groupedStockists = groupStockistsByRegionAndCounty(filteredStockists);

  // Get the list of available regions
  const availableRegions = Object.keys(groupedStockists).map((region) =>
    slugifyRegion(region),
  );

  if (hide || !data.stockists) {
    return <></>;
  }

  return (
    <section
      id={id}
      className={`${combinedClasses}`}
      style={{ ...bgImgStyles, backgroundColor }}
    >
      <div
        className={`content content-flow border-top pt-10 ${!nested ? data.wrapper_width : ""}`}
      >
        {/*  Postcode search bar  */}
        <div id="postcode-search" className="search-wrapper mb-10">
          <form
            onSubmit={(e) => e.preventDefault()}
            onFocus={() => setIsFocussed((prev) => !prev)}
            onBlur={() => setIsFocussed((prev) => !prev)}
          >
            <label className="block font-semibold">
              {data.search_input_label
                ? data.search_input_label
                : "FIND YOUR NEAREST DEALER BY ENTERING YOUR POSTCODE BELOW"}
              <input
                className="block mt-2.5 w-full h-[48px] bg-[#F1F1F1] px-5
                border-l border-t border-solid border-pn-placeholder-base
                transition-all ease-in-out duration-400 hover:ease-in-out focus:ease-in-out
                hover:outline hover:outline-2 hover:outline-[#1D9ABD]/30 hover:bg-white hover:duration-400
                focus:outline-2 focus:outline focus:outline-[#1D9ABD]/30 focus:bg-white focus:duration-400"
                type="text"
                name="postcode"
                required
                value={postcode}
                onChange={handlePostcodeSearch}
                placeholder={`${isFocussed ? "" : "Postcode"}`}
              />
            </label>
          </form>
        </div>

        {/*  Locations - Map & Accordions  */}
        <Grid
          classes={`grid-cols-1 md:grid-cols-2 podcast-feed same-height-group`}
        >
          {/* Column One - SVG Map */}
          <Column>
            <SVGMap
              availableRegions={availableRegions}
              setAccordion={handleAccordionToggle}
            />
          </Column>

          {/* Column Two - Accordions */}
          <Column>
            {Object.keys(groupedStockists).map((region, index) => (
              <LocationAccordions
                key={region}
                region={region}
                regionSlug={slugifyRegion(region)}
                counties={groupedStockists[region]}
                isOpen={openAccordion === region}
                onToggle={() => handleAccordionToggle(region)}
              />
            ))}
          </Column>
        </Grid>
      </div>
    </section>
  );
}
