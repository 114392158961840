"use client";

import clsx from "clsx";
import React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ButtonSpinner } from "@/components/buttons/ButtonSpinner";
import { sendGTMEvent } from '@next/third-parties/google'

const contactFormSchema = yup
  .object({
    name: yup.string().required("Please fill in your name"),
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Please fill in your email"),
    message: yup.string().required("Please fill in your message"),
  })
  .required();

type FormType = yup.InferType<typeof contactFormSchema>;

interface FormProps {
  hash: string;
  formID: number;
}

export const ContactForm = ({ hash, formID }: FormProps) => {
  const [isWaitingForAResponse, setIsWaitingForAResponse] =
    React.useState(false);
  const [showSuccessPage, setShowSuccessPage] = React.useState(false);
  const [error, setError] = React.useState("");
  const successRef = React.useRef<HTMLDivElement>(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    resolver: yupResolver(contactFormSchema),
  });

  const WPCF7_FORM_ID = hash; // CF7 Shortcode ID
  const FORM_ID = formID; // CF7 Post ID

  // Submit Handler...
  const onSubmit = async (data: FormType) => {
    setError(""); // Reset Error
    setIsWaitingForAResponse(true);

    const formData = new FormData();

    formData.append("_wpcf7_unit_tag", WPCF7_FORM_ID);
    formData.append("your-name", data.name);
    formData.append("your-email", data.email);
    formData.append("your-message", data.message ?? "");
    formData.append("your-subject", "Main Contact Form");

    const response = (await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/wp-json/contact-form-7/v1/contact-forms/${FORM_ID}/feedback`,
      {
        method: "post",
        body: formData,
      },
    ).then((res) => res.json())) as {
      status: "validation_failed" | "mail_sent";
      message: string;
    };

    if (response.status === "validation_failed") {
      setError(response.message);
    } else {
      setIsWaitingForAResponse(false);

      // Add email to the dataLayer
      sendGTMEvent({
        event: `contact_form_submit`,
        userEmail: data.email,
      });

      setShowSuccessPage(true);
    }
  };

  React.useEffect(() => {
    if (isSubmitSuccessful && !error) {
      reset();
    }
  }, [isSubmitSuccessful, reset, error]);

  React.useEffect(() => {

    if (showSuccessPage) {
      setTimeout(() => {
        // Handle form submission
        if (successRef?.current) {
          const yOffset = -200; // Adjust this value for the amount of space you want above the success message
          const y = successRef.current.getBoundingClientRect().top + window.scrollY + yOffset;
          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 300)
    }

  }, [showSuccessPage])

  if (showSuccessPage) {
    return (
      <div ref={successRef} className="relative w-full p-5 mb-10 bg-pn-off-white-base text-pn-black-base">
        <p className="confirmation-message success flex items-center">
          Your message has been successfully sent.
        </p>
      </div>
    );
  }

  return (
    <form
      className={clsx("w-full relative z-0 pb-10")}
      onSubmit={handleSubmit(onSubmit)}
      id={"contact-form"}
    >
      <div className="pt-15 flex flex-col items-start space-y-10 relative z-10">
        <div className="flex flex-col space-y-[1.875rem] w-full">
          <div
            className="grid grid-cols-2 gap-[3.875rem] self-stretch justify-between
          max-md:flex max-md:flex-col max-md:gap-0 max-md:space-y-[1.875rem] group"
          >
            {/* Full Name  */}
            <div className="flex flex-col space-y-[0.625rem]">
              <label
                htmlFor="name"
                className={clsx(
                  "text-sm text-pn-black-base uppercase font-semibold",
                )}
              >
                Full Name*
              </label>
              <input
                {...register("name")}
                placeholder={`John Smith`}
                className={clsx(
                  "border-box text-pn-black-base h-12 flex items-center px-5 bg-white w-full",
                  "placeholder:text-pn-placeholder-base border border-solid outline outline-2 outline-transparent",
                  "hover:outline-pn-news-base/30 focus:outline-pn-news-base/30 focus:placeholder:text-transparent",
                  errors.name ? "border-pn-red-base" : "border-pn-black-base",
                )}
              />
              {errors.name && (
                <p className="small-print-regular text-pn-red-base">
                  {errors.name.message}
                </p>
              )}
            </div>

            {/*  Email  */}
            <div className="flex flex-col space-y-[0.625rem]">
              <label
                htmlFor="email"
                className={clsx(
                  "text-sm text-pn-black-base uppercase font-semibold",
                )}
              >
                Email*
              </label>
              <input
                {...register("email")}
                type="email"
                placeholder={`john@example.com`}
                className={clsx(
                  "border-box text-pn-black-base h-12 flex items-center px-5 bg-white w-full",
                  "placeholder:text-pn-placeholder-base border border-solid outline outline-2 outline-transparent",
                  "hover:outline-pn-news-base/30 focus:outline-pn-news-base/30 focus:placeholder:text-transparent",
                  errors.email ? "border-pn-red-base" : "border-pn-black-base",
                )}
              />
              {errors.email && (
                <p className="small-print-regular text-pn-red-base">
                  {errors.email.message}
                </p>
              )}
            </div>
          </div>

          <div
            className="grid grid-cols-1 gap-[3.875rem] self-stretch justify-between
               max-md:flex max-md:flex-col max-md:gap-0 max-md:space-y-[1.875rem]"
          >
            <div className="flex flex-col space-y-[0.625rem]">
              <label
                htmlFor="message"
                className={clsx(
                  "text-sm text-pn-black-base uppercase font-semibold",
                )}
              >
                Your Message*
              </label>
              <textarea
                {...register("message")}
                rows={20}
                className={clsx(
                  "border-box text-pn-black-base block h-12 py-2 px-5 bg-white w-full",
                  "placeholder:text-pn-placeholder-base border border-solid outline outline-2 outline-transparent",
                  "hover:outline-pn-news-base/30 focus:outline-pn-news-base/30 min-h-[5lh]",
                  errors.message
                    ? "border-pn-red-base"
                    : "border-pn-black-base",
                )}
              />
              {errors.message && (
                <p className="small-print-regular text-pn-red-base">
                  {errors.message.message}
                </p>
              )}
            </div>
          </div>

          {/*  Submit Button  */}
          <button
            type="submit"
            className="bg-pn-black-base text-white rounded-btn py-2.5 sm:py-5
             min-w-[150px] w-1/2 disabled:opacity-50 font-semibold leading-tight"
            disabled={isWaitingForAResponse}
          >
            {isWaitingForAResponse ? <ButtonSpinner /> : "Send"}
          </button>

          <p className="required text-sm mt-5 text-pn-black-base">
            * Required field
          </p>
        </div>

        {showSuccessPage && (
          <div className="relative !mt-5 text-pn-learning-base">
            <p className="confirmation-message">
              Thank you for your enquiry! We&apos;ll be in touch shortly.
            </p>
          </div>
        )}
      </div>
    </form>
  );
};
