"use client";

// External imports
import React from "react";
import clsx from "clsx";

// Internal imports
import { getDefaultACFSettings } from "@/helpers/getDefaultACFSettings";
import { LayoutIntro } from "@/components/LayoutIntro";
import { usePaginationWithMaxPages } from "@/hooks/usePaginationWithMaxPages";
import { SinglePodcast } from "@/components/acfFlexibleContentLayouts/PodcastFeed/SinglePodcast";
import { ButtonSpinner } from "@/components/buttons/ButtonSpinner";
import { Grid } from "@/components/layout/Grid";

// Type Imports
import {
  IPodcastFeed,
  IPodcastFeedResponse,
} from "@/types/ACFLayouts/PodcastFeed";

type Props = {
  data: IPodcastFeed;
  nested?: boolean;
};

export const PodcastFeed = ({ data, nested = false }: Props) => {
  const [podcastFeed, setPodcastFeed] = React.useState<IPodcastFeedResponse[]>(
    data.podcasts,
  );
  const initialData = true;

  // Default ACF Settings
  const { hide, paddings, margins, classes, id, backgroundColor, bgImgStyles } =
    getDefaultACFSettings(data);

  let queryParams: Record<string, string> = {
    _fields: "id,acf,title,featured_image,date",
  };

  if (data.exclude_episodes) {
    queryParams.exclude = data.exclude_episodes.join();
  }

  // SWR Fetcher
  const {
    data: podcastData,
    isLoading,
    maxPages,
    page,
    setPage,
  } = usePaginationWithMaxPages<IPodcastFeedResponse>({
    requestUrl: `${process.env.NEXT_PUBLIC_API_URL}/wp-json/wp/v2/podcasts`,
    perPage: 10,
    setGridElements: setPodcastFeed,
    queryParams: queryParams,
    initialData: initialData,
  });

  // Group all main layout classes here
  const combinedClasses = clsx(
    "relative",
    "acf-layout",
    "podcast-feed-block",
    "full-wrapper",
    paddings,
    margins,
    classes,
  );

  if (hide) return <></>;

  return (
    <>
      <div
        id={id}
        className={`${combinedClasses}`}
        style={{ ...bgImgStyles, backgroundColor }}
      >
        <div
          className={`${!nested ? data.wrapper_width : ""} pt-10 border-t-[0.5px] border-solid border-pn-black-base`}
        >
          {/* Intro */}
          {data.intro && <LayoutIntro intro={data.intro} />}

          <Grid
            classes={`podcast-feed grid-col-1 same-height-group w-full wrap`}
          >
            {/*  Podcast loop  */}
            {podcastFeed &&
              podcastFeed.map((podcast, index) => {
                return <SinglePodcast key={podcast.id} podcast={podcast} />;
              })}
          </Grid>

          <button
            className={clsx(
              `btn podcast-btn w-full mt-10 font-medium`,
              page >= maxPages && "opacity-50",
              isLoading ? "!bg-pn-black-base" : "",
            )}
            onClick={() => setPage((prev) => prev + 1)}
            disabled={page >= maxPages}
          >
            {isLoading ? <ButtonSpinner /> : "Load More"}
          </button>
        </div>
      </div>
    </>
  );
};
