import React from "react";
import { IPage } from "@/types/wordpress-types";
import parse from "html-react-parser";

interface CompareButtonProps {
  comparisonProducts: IPage[] | null;
  value: IPage | null;
  handleClick: (value: IPage | null) => void;
  isOpen: boolean;
  handleToggle: (menuId: string) => void;
}

const CompareButton = React.forwardRef<HTMLUListElement, CompareButtonProps>(
  ({ comparisonProducts, value, handleClick, isOpen, handleToggle }, ref) => {
    // Handle click events outside the submenu to close it...
    React.useEffect(() => {
      const closeOpenMenus = (e: MouseEvent) => {
        // Ensure that ref is correctly asserted as MutableRefObject
        const menuRef = ref as React.MutableRefObject<HTMLUListElement | null>;

        if (
          menuRef.current &&
          isOpen &&
          !menuRef.current?.contains(e.target as Node) &&
          !(
            e.target instanceof HTMLElement && e.target.id === "compare-product"
          )
        ) {
          handleToggle("compare-product");
        }
      };

      document.addEventListener("mousedown", closeOpenMenus);

      // Cleanup the event listener on component unmount
      return () => {
        document.removeEventListener("mousedown", closeOpenMenus);
      };
    }, [handleToggle, isOpen, ref]);

    return (
      <>
        <button
          id="compare-product"
          className={`min-w-[142px] max-md:hidden h-10 p-5 flex basis-auto justify-center items-center rounded-full 
          border border-solid border-pn-black-base relative order-posts-toggle 
          ${isOpen ? "bg-pn-off-white-base" : "bg-white"}`}
          onClick={() => handleToggle("compare-product")}
          aria-label="Order posts menu button"
        >
          Compare
          {isOpen && (
            <ul
              ref={ref}
              className="filter-menu absolute list-none bg-white text-left min-w-[200px]
                top-full right-2.5 z-10 shadow-pn pb-1"
            >
              <li className="px-2.5 py-1.5" onClick={() => handleClick(null)}>
                Remove
              </li>

              {comparisonProducts &&
                comparisonProducts?.map((product, index) => {
                  return (
                    <li
                      key={product?.id}
                      className={`${value?.id === product?.id ? "active" : ""} flex items-center px-2.5 py-1.5`}
                      onClick={() => handleClick(product)}
                    >
                      <span className="checkbox w-6 h-6 inline-flex justify-center items-center pr-1.5"></span>
                      {parse(product?.title?.rendered)}
                    </li>
                  );
                })}
            </ul>
          )}
        </button>
      </>
    );
  },
);

CompareButton.displayName = "CompareButton";
export default CompareButton;
