"use client";

import { IPodcastFeedResponse } from "@/types/ACFLayouts/PodcastFeed";
import Image from "next/image";
import { PodcastDescription } from "@/components/acfFlexibleContentLayouts/PodcastFeed/PodcastDescription";
import React from "react";
import parse from "html-react-parser";
import { FullPodcastDescription } from "@/components/acfFlexibleContentLayouts/PodcastFeed/FullDescription";

export function SinglePodcast({ podcast }: { podcast: IPodcastFeedResponse }) {
  const [isShowMore, setShowMore] = React.useState(false);
  const date = new Date(podcast.date);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
  };
  const formattedDate = date.toLocaleDateString("en-GB", options);

  function handleToggleContent() {
    setShowMore((prev) => !prev);
  }

  return (
    <div className={`relative w-full max-w-full min-w-0 border-bottom`}>
      <div className="flex flex-wrap mb-5 max-w-full gap-5 md:gap-x-10">
        <div className="flex max-[319px]:flex-wrap gap-5 md:gap-x-10">
          {/* Podcast Image */}
          <div className="relative shrink-0 h-[130px] w-[130px] md:w-[210px] md:h-[140px]">
            <Image
              src={`${process.env.NEXT_PUBLIC_API_URL}${podcast?.featured_image}`}
              alt={""}
              fill
              sizes="(max-width: 320px) 50vw, 20vw"
              className={`object-cover object-center`}
            />
          </div>

          {/* Podcast Content */}
          <div className="podcast-content w-full overflow-hidden whitespace-normal content content-flow basis-full">
            {/* Podcast Title */}
            <div className="podcast-title md:flex md:items-center md:gap-x-2.5">
              <h2 className="md:inline-block">{`#${podcast.acf.episode_number} ${parse(podcast.title.rendered)}`}</h2>
              <p className="font-roboto-serif font-light md:inline-block">
                {formattedDate}
              </p>
            </div>

            {/* Podcast Description */}
            <PodcastDescription
              showMore={isShowMore}
              toggleShowMore={handleToggleContent}
              podcast={podcast}
            />
          </div>
        </div>

        {isShowMore && (
          <FullPodcastDescription
            podcast={podcast}
            toggleContent={handleToggleContent}
            classes={"col-span-12 md:hidden"}
          />
        )}
      </div>
    </div>
  );
}
